:root {
  --color-primary: #eb2f64;
  --white-font-color: #f0ffff;
  --primary-linear-gradient: linear-gradient(
    to right,
    rgba(236, 0, 140, 0.8),
    rgba(183, 33, 255, 0.8)
  );
  --xs-font: 1rem;
  --s-font: 1.5rem;
  --m-font: 2rem;
  --xl-font: 5rem;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  //   background-color: var(--color-primary);
}
