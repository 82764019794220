.dropdown-item {
  font-size: 1.5rem;
}

.dropdown-menu {
  padding: 1rem;
}

.carousel-inner {
  text-align: center;
}

.pad-5 {
  padding: 5rem;
}

.text-cap {
  text-transform: capitalize;
}
