.logo {
  width: 5rem;
  height: auto;
}

.logo__text {
  padding: 0;
  margin: 0;
  margin-left: 0.2rem;
  margin-top: 0.5rem;
}

.logo__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  z-index: 100;
}

.navbar {
  padding: 0;
}

.menu {
  width: 100%;
  padding: 1rem 5rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  position: sticky;
  background-image: linear-gradient(
    to right,
    rgba(236, 0, 140, 0.8),
    rgba(183, 33, 255, 0.8)
  );

  &__item {
    // flex: 1 1 100%;
    text-transform: capitalize;
    font-size: 2rem;
    cursor: pointer;
    color: var(--white-font-color);
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: rgba(240, 255, 255, 0.3);
      // border-bottom: 2px solid var(--white-font-color);
    }

    & a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
      height: 2.5rem;
      width: 2.5rem;
      fill: var(--white-font-color);

      &:hover {
        color: #000;
      }
    }
  }
}

.content {
  width: 100%;
  min-height: 100vh;
  // margin-top: -4rem;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;

  &__header {
    padding: 5rem;
    display: flex;
    overflow: hidden;
  }

  &__trailer {
    width: 100%;
    flex: 0 0 45%;
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-player {
      width: 90%;
      height: auto;
    }

    &-text {
      color: var(--white-font-color);
      font-size: 1.5rem;
      margin: 0.5rem 0;
    }
  }

  &__metadata {
    flex: 1 1 40%;
    min-width: 300px;

    &-title {
      text-transform: capitalize;
      font-size: 4rem;
      color: var(--white-font-color);
      width: 100%;
    }

    &-genre {
      margin-top: 2rem;
      text-transform: capitalize;
      font-size: 2rem;
      color: var(--white-font-color);
      width: 100%;
    }

    &-synopsis {
      margin: 3rem 0;
      font-size: 1.5rem;
      color: var(--white-font-color);
    }
  }
}

.rating {
  display: flex;
  margin-top: 10rem;
  // justify-content: space-between;

  &__box {
    // border: 2px solid var(--color-primary);
    // border-image: linear-gradient(to right, #3acfd5 0%, #3a4ed5 100%) 1;
    border-image: linear-gradient(
        to right,
        rgba(236, 0, 140, 100%),
        rgba(183, 33, 255, 100%)
      )
      1;
    border-width: 2px;
    border-style: solid;
    width: 13rem;
    height: 10rem;
    color: var(--white-font-color);
    padding: 0.5rem;
    margin: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 255, 255, 0.5);
    text-shadow: 0 0 3px #000;

    &-name {
      font-size: 1rem;
      text-align: center;
    }

    &-score {
      font-size: 2rem;
      font-weight: 500;
      text-align: center;
    }

    &-share:hover {
      cursor: pointer;
      background-color: rgba(240, 255, 255, 0.5);
    }

    &-share-icon {
      height: 4.5rem;
      width: 4.5rem;
      fill: var(--white-font-color);
    }
  }

  &__box:nth-child(1) {
    margin-left: 0;
  }
}

.language {
  color: var(--white-font-color);
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0;

  &__box {
    display: flex;
    padding: 2rem 0;
    align-items: center;
    flex: 0 0 25%;

    &-icon {
      height: 4rem;
      width: 4rem;
      fill: var(--white-font-color);
      margin-right: 2rem;
    }

    &-detail {
      font-size: 1.5rem;
    }
  }
}

.cast {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__heading {
    color: var(--white-font-color);
  }

  &__group {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__box {
    width: 12rem;
    margin: 0 1.5rem;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    &-name {
      padding: 1rem;
      color: var(--white-font-color);
      font-size: 1.5rem;
      word-break: normal;

      &:hover {
        animation: flicker 1.5s infinite alternate;

        & > cast__box {
          border: 1px solid white;
        }
      }
    }
  }
}

.content-action__btn {
  position: relative;
  padding: 3rem;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 5rem 0rem;
  // width: 30%;
  color: var(--white-font-color);
  border: 2px solid var(--white-font-color);
  background-color: transparent;
  // transition: opacity 300ms cubic-bezier(0.694, 0, 0.335, 1),
  //   background-image 100ms cubic-bezier(0.694, 0, 0.335, 1),
  //   color 100ms cubic-bezier(0.694, 0, 0.335, 1);
  border-radius: 10px;

  &:hover {
    background-image: linear-gradient(to right, #ec008c, #b721ff);
    // font-size: 3rem;
  }
}

.director {
  padding: 3rem 0;
  margin: 2rem 0;
  display: flex;
  align-items: center;

  &__icon {
    width: 10rem;
    padding: 1rem;
    border: 2px solid var(--white-font-color);
    border-radius: 50px;
  }

  &__name {
    font-size: 2rem;
    margin: 0 1rem;
    color: var(--white-font-color);
  }
}

.loader {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  &__gif {
    width: 12rem;
    height: auto;
  }
}

//**** Listing page ****
.home-listing {
  width: 100%;
  background: linear-gradient(
    180.2deg,
    rgb(30, 33, 48) 6.8%,
    rgb(74, 98, 110) 131%
  );
  min-height: 100vh;
}

.content-listing {
  width: 100%;
  background: linear-gradient(
    180.2deg,
    rgb(30, 33, 48) 6.8%,
    rgb(74, 98, 110) 131%
  );
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.listing-container {
  // margin-top: 5rem;
  display: flex;
  flex-direction: row;
  position: relative;
}

.content-container {
  padding: 5rem 5rem;
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
}

.filter-icon {
  width: 5rem;
  height: 5rem;
  margin-top: 5rem;
  padding: 2rem;
  transition: all 0.2s ease-in;

  &:hover {
    cursor: pointer;
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
  }
}

.card {
  width: 21rem;
  border: 1px solid var(--white-font-color);
  background-color: var(--white-font-color);
  border-radius: 3px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
  max-height: 44.4rem;

  &:hover > &__header {
    transform: scale(1.1);
  }

  &__header {
    // background-size: cover;
    width: 100%;
    height: 30rem;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &__detail {
    top: auto;
    // height: 15rem;
    background-color: var(--white-font-color);

    &-title {
      color: black;
      height: 2rem;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
      margin: 0.5rem 0;
      transition: all 0.2s ease-in-out;
    }

    &-synopsis {
      color: black;
      font-size: 1.2rem;
      margin: 0.5rem 0.5rem;
    }

    &-genre {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__button {
    width: 100%;
    padding: 0.5rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    cursor: pointer;

    align-items: center;
    // background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    background-image: linear-gradient(to right, #ec008c, #b721ff);
    border: 0;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: var(--white-font-color);
    display: flex;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;

    a:has(> &) {
      text-decoration: none;
    }

    &:active,
    &:hover {
      outline: 0;
    }

    & span {
      background-color: rgb(5, 6, 45);
      padding: 16px 24px;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      transition: all 300ms linear;
    }

    &:hover span {
      background: none;
    }
  }
}

.genre {
  width: fit-content;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 20px;
  color: var(--white-font-color);
}

//******** Hero ********

.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.5s;

  &__video-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
    z-index: -1;

    &-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-image: linear-gradient(to bottom right, #002f4b, #dc4225);
      opacity: 0.8;
    }

    &-title {
      font-size: 3rem;
      color: var(--white-font-color);
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.5rem;
      z-index: 10;
    }

    &-sub {
      font-size: 2rem;
      color: var(--white-font-color);
      text-transform: capitalize;
      font-weight: 300;
      letter-spacing: 0.25rem;
      z-index: 10;
    }
  }
}

//****** Filter ******

.filter {
  width: 100%;
  padding: 3rem 5rem;
  flex: 0 0 20%;
  padding-bottom: 0;
  color: var(--white-font-color);
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.19);
  // border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.8px);
  -webkit-backdrop-filter: blur(3.8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  // transform: translateX(100%);
  transition: appear 300ms ease-in-out;

  &__close-icon {
    fill: var(--white-font-color);
    height: 2.5rem;
    width: 2.5rem;

    &:hover {
      background: rgba(255, 255, 255, 0.19);
      cursor: pointer;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // text-transform: uppercase;
    // font-size: 2rem;
    // margin-top: -2rem;
  }

  &__form {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  }

  &__query {
    padding: 1rem;
    display: flex;
    align-items: center;
    width: 100%;

    &-searchbox {
      font-family: inherit;
      font-size: inherit;
      color: var(--white-font-color);
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0.7rem 2rem;
      border-radius: 20px;
      width: 90%;
      transition: all 0.2s;
      margin-right: -3.5rem;

      &:focus {
        outline: none;
        width: 100%;
        background: rgba(255, 255, 255, 0.19);
      }

      &::-webkit-input-placeholder {
        font-weight: 100;
        color: var(--white-font-color);
        font-size: inherit;
      }
    }

    &-btn {
      background-color: transparent;
      padding: 0.5rem;
      border: none;
    }

    &-icon {
      height: 2rem;
      width: 2rem;
      fill: rgba(255, 255, 255, 0.19);
    }
  }

  &__genre {
    margin: 1rem 0;
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    font-size: inherit;
    padding: 1rem 2rem;

    &_radio {
      appearance: none;
      -webkit-appearance: none;
      width: 2rem;
      height: 2rem;
      border: 1px solid #fff;
      border-radius: 50%;
      position: relative;
      background-color: transparent;
      cursor: pointer;

      &:checked::before {
        content: "";
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        background-color: var(--color-primary);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: appear 0.8s;
      }

      &-label {
        font-size: 1.4rem;
        // font-weight: 100;
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;

    &-label {
      font-size: 1.4rem;
      margin: 3rem;
    }

    &-btn {
      background-color: transparent;
      padding: 0.5rem;
      border: none;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        background-color: rgba(255, 255, 255, 0.19);
      }
    }

    &-icon {
      width: 3rem;
      height: 3rem;
      fill: var(--white-font-color);

      &_active {
        fill: var(--color-primary);
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
      }
    }
  }

  &__reset {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    &-btn {
      padding: 1rem 2rem;
      background-color: transparent;
      color: var(--white-font-color);
      font-size: 1.4rem;
      border: 1px solid rgba(255, 255, 255, 0.3);
      text-transform: uppercase;

      &:hover {
        background-color: rgba(255, 255, 255, 0.19);
        cursor: pointer;
      }
    }
  }
}

//******* Setting *******
.filter-buttons {
  width: 100%;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 3rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 1rem 1rem;

  & legend {
    text-transform: capitalize;
    font-size: inherit;
    font-weight: 200;
    text-align: center;
  }

  & input {
    appearance: none;
    margin: 0.2rem;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2rem;
    padding: 1rem 2rem;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    transition: all 0.1s;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &:checked {
      border: none;
      background: -webkit-linear-gradient(right, #ec008c, #b721ff);
      box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
      /* text-shadow: 0 1rem 0rem #808080; */
      /* text-shadow: 0 1rem 0rem rgba(0, 0, 0, 0.5); */
    }

    &::before {
      content: attr(label);
      text-align: center;
      text-transform: capitalize;
    }
  }
}

.no-content {
  color: var(--white-font-color);
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  margin: 4.5rem;

  &__icon {
    fill: var(--white-font-color);
  }

  &__text {
    margin-top: 3rem;
  }
}

////Image slider///
.slider-container {
  height: 80vh;
  // position: relative;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

// .slide {
//   width: 80%;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0 auto;
// }

.poster-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.poster-button {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 4rem;
  background-image: linear-gradient(to right, #ec008c, #b721ff);
  color: var(--white-font-color);
  border: none;
  cursor: pointer;
  border-radius: 50px;
}

/// Highlights
.highlight {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 2rem;
    color: var(--white-font-color);
    text-transform: capitalize;
    margin: 1.5rem 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__viewmore {
    height: 45rem;
    width: 21rem;
    background-color: transparent;
    border: 1px solid var(--white-font-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &:hover > &-icon {
      transform: scale(1.1);
    }

    &-icon {
      height: 10rem;
      width: 10rem;
      fill: var(--white-font-color);
      padding: 2rem;
      transition: all 0.2s linear;
    }

    &-span {
      text-decoration: none;
      color: var(--white-font-color);
      font-size: 1.5rem;
    }
  }
}

.footer {
  // border-top: linear-gradient(
  //     to right,
  //     rgba(236, 0, 140, 100%),
  //     rgba(183, 33, 255, 100%)
  //   )
  //   1;

  background: linear-gradient(to top, #dfe9f3 0%, white 100%);
  padding: 5rem;
  padding-bottom: 1rem;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;

  &__left {
    flex: 1 1 60%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__logo {
    width: 10rem;

    &-text {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  &__terms {
    text-decoration: none;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    font-size: 1.2rem;
  }

  &__address {
    font-size: 1.2rem;
  }

  &__right {
    flex: 1 1 30%;
  }

  &__newsletter-form {
    width: 100%;

    & input {
      padding: 1rem;
      width: 60%;
      border: none;
      transition: all 0.2s ease-in;

      &:focus {
        outline: none;
        background: var(--white-font-color);
      }
    }

    & button {
      color: var(--white-font-color);
      border: none;
      padding: 1rem;
      background-image: linear-gradient(to right, #ec008c, #b721ff);
    }
  }
}

.request__screener {
  &-form {
    font-size: 1.2rem;
    padding: 1rem;
  }

  &-btn {
    border: none;
    padding: 1rem;
  }
}

.submit {
  color: var(--white-font-color);
  background-image: linear-gradient(to right, #ec008c, #b721ff);
}

.contact {
  padding: 5rem 10rem;
  background-image: linear-gradient(
    to top,
    rgba(136, 0, 140, 0.8),
    rgba(128, 0, 128, 0.1) 10%,
    rgba(128, 0, 128, -1)
  );

  &__submit {
    border: none;
    padding: 1rem;
    color: var(--white-font-color);
    background-image: linear-gradient(to right, #ec008c, #b721ff);
  }
}

.login {
  padding: 10rem 15rem;
  background-image: linear-gradient(
    to top,
    rgba(136, 0, 140, 0.3),
    rgba(128, 0, 128, 0.1) 10%,
    rgba(128, 0, 128, -1)
  );
  font-size: 1.5rem;
  min-height: 75vh;
}

.import {
  padding: 5rem;
  // background-image: linear-gradient(
  //   to top,
  //   rgba(136, 0, 140, 0.3),
  //   rgba(128, 0, 128, 0.1) 10%,
  //   rgba(128, 0, 128, -1)
  // );
  font-size: 1.5rem;
  min-height: 75vh;

  &__title {
    margin-top: 2rem;
  }

  &__container {
    margin-top: 2rem;
    display: flex;
  }
}

.update {
  padding: 5rem;
  font-size: 1.5rem;
  min-height: 75vh;
}

.update-content {
  padding: 1rem;
  font-size: 1.5rem;
  margin-left: 1rem;
  border-radius: 30px;

  & a {
    text-decoration: none;
  }
}

.search-loading {
  color: var(--white-font-color);
  font-size: 3rem;
}
